.filter-dropdown-container {
    @apply bg-white rounded-none border-1 px-4 text-left cursor-pointer focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-primary sm:text-sm;
    z-index: 1 !important;
}

.filter-dropdown__control {
    @apply border-0 focus:outline-none ring-0 focus:ring text-base !important;
}

.filter-dropdown__input > input {
    @apply focus:outline-none focus:border-primary ring-0 border-none !important;
}

.filter-dropdown__menu {
    @apply border border-black rounded-none mt-0 -left-px shadow-none !important;
    width: calc(100% + 2px) !important;
}

.filter-dropdown__menu-list {
    @apply pt-0 pb-0 !important;
}

.filter-dropdown__option {
    @apply pl-6 py-4 !important;
}

.filter-dropdown__option.selected:hover {
    @apply bg-gray-300;
}
