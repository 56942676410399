.env-banner {
    @apply uppercase absolute text-white;
    top: 50%;
    left: auto;
    right: 1rem;
}

.env-banner > div {
    @apply relative bg-primary px-2 py-1;
    top: -1.25rem;
    right: 1rem;
}

@media (min-width: 768px) {
    .env-banner {
        left: 50%;
        right: initial;
    }

    .env-banner > div {
        left: calc(-50% + 0.75rem);
        right: initial;
        top: 0;
    }
}
