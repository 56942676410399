#root .ant-table {
    background-color: transparent;
}

#root .ant-table th {
    @apply uppercase font-bold text-gray-400 border-gray-300;
}

#root .ant-table td {
    @apply border-gray-300;
}

#root .ant-table-cell-row-hover {
    background-color: inherit;
}

#root .ant-table .dropdown {
    @apply rounded hover:bg-gray-200 transition;
}
