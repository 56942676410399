#audience-filters .rc-collapse {
    @apply bg-white border-none;
}

#audience-filters .rc-collapse-item {
    @apply border-none;
}

#audience-filters .rc-collapse-header {
    @apply px-0 select-none;
}

#audience-filters .rc-collapse-content.rc-collapse-content-active {
    @apply overflow-visible;
}
