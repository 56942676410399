.modal > .container {
    width: calc(100% - 20px);
}

.z-10 {
    z-index: 10;
}

@media (min-width: 768px) {
    .modal > .container {
        width: auto;
    }
}
