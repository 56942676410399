.mapboxgl-control-container {
    display: none;
}

.mapboxgl-popup-content {
    font-family: "GT-Flexa", sans-serif;
    font-size: 14px;
}

.mapboxgl-popup-content .store-type {
    padding: 4px 10px;
    border-radius: 10px;
    background: rgba(134, 134, 134, 0.1);
    display: inline-block;
    margin-top: 5px;
}

.map-store{
    position: relative;
    min-height: 500px;
}

.info-map {
    position: absolute;
    top: 5px;
    right: 5px;
    background: white;
    padding: 0.4rem;
    z-index: 1;
    font-family: "GT-Flexa", sans-serif;
}
