table tr {
    @apply border-b border-gray-300;
}

table tr:hover {
    @apply bg-gray-100;
}

table th {
    @apply py-2 px-4 text-sm text-gray-300 uppercase text-left;
}

table td {
    @apply px-4 py-4 text-sm;
}

table tbody tr:last-child {
    border-bottom: none;
}
