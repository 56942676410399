.store-address-cell {
    min-width: 100px;
    max-width: 200px;
}

.store-address-cell div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
