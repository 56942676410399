.pac-container:after {
    background-image: none !important;
    height: 0px;
}

.pac-icon-marker {
    display: none;
}

.pac-logo {
    border-radius: 0px !important;
    font-family: GT-Flexa, sans-serif;
    font-size: inherit;
    border: 1px solid black;
}

.pac-item {
    @apply pl-6 py-4 !important;
    @apply focus:outline-none focus:border-primary ring-0 border-none !important;
}

.pac-item:hover .pac-item-query {
    background-color: #F2F2F2;
    color: #FF7700;
}

.pac-item:hover {
    background-color: #F2F2F2;
    color: #FF7700;
}