#root .info-tooltip {
    border: 1px solid #ff7700;
    border-radius: 0;
    opacity: 1;
}

#root .info-tooltip:before {
    border: 1px solid #ff7700;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    margin-left: -5px;
    bottom: -6px;
}
