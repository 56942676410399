body .ant-dropdown-menu-root,
body .ant-dropdown-menu-sub {
    @apply border border-black;
}

body .ant-dropdown-menu-submenu-title {
    @apply py-3;
}

body .ant-dropdown-menu-submenu-title:hover {
    @apply bg-transparent;
}

body .ant-dropdown-menu-item {
    @apply py-3;
}

body .ant-dropdown-menu-item .active {
    @apply text-primary font-bold;
}

body .ant-dropdown-menu-item:hover,
body .ant-dropdown-menu-submenu:hover {
    @apply bg-primary bg-opacity-10;
}

body .ant-dropdown-menu-item-divider {
    @apply bg-black bg-opacity-20;
}
