.pattern-field {
    @apply border-2 border-black relative font-bold text-center leading-10;
    font-size: 1.2rem;
    font-family: monospace;
}

.pattern-field .selection {
    @apply border-2 border-primary rounded-md relative font-bold text-center leading-7 inline-block bg-primary/20;
    margin-right: -2px;
    margin-left: -2px;
}

.pattern-field pre {
    font-size: 1.2rem;
    font-family: monospace;
}

.cursor-highlight {
    cursor: url('/src/components/Icons/stabilo-green.png'), auto;
}
