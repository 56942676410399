@font-face {
    font-family: 'GT-Flexa';
    src: url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Regular.otf') format('otf'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Regular.ttf') format('ttf'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Regular.woff') format('woff'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Regular.woff2') format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: 'GT-Flexa';
    src: url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Bold.otf') format('otf'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Bold.ttf') format('ttf'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Bold.woff') format('woff'),
        url('/src/fonts/GT-Flexa/GT-Flexa-Standard-Bold.woff2') format('woff2');
    font-weight: bold;
}

.grey-card {
    opacity: 0.5;
    cursor: not-allowed;
    color: #c4c4c4;
}

.plus-icon {
    background-color: orange;
    color: white;
    font-weight: bold;
}

/*Recaptcha : hide icon*/
.grecaptcha-badge {
    display: none !important;
    opacity: 0 !important;
}

.text-ellipsis {
    text-overflow: ellipsis;
}

#root .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

#root .ant-spin-dot {
    width: 2rem;
    height: 2rem;
}

#root td.ant-table-column-sort {
    background: inherit;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
