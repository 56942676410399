.react-select-container {
    @apply w-full bg-white relative rounded-none border border-1 px-4 text-left sm:text-sm;
}

.react-select-container * {
    cursor: text !important;
}

.react-select__control {
    @apply border-0 focus:outline-none ring-0 focus:ring text-base min-h-0 !important;
}

.react-select__value-container {
    @apply py-0 !important;
}

.react-select__input > input {
    @apply focus:outline-none focus:border-primary ring-0 border-none !important;
}

.react-select__menu {
    @apply border border-black rounded-none mt-0 -left-px shadow-none !important;
    width: calc(100% + 2px) !important;
}

.react-select__menu-list {
    @apply pt-0 pb-0 !important;
}

.react-select__option {
    @apply pl-6 py-4 !important;
}

.react-select__option.selected:hover {
    @apply bg-gray-300;
}

.react-select__indicator {
    @apply px-0 !important;
}

.react-select__placeholder {
    @apply text-none !important;
}

input:focus {
    outline: none !important;
    box-shadow: none !important;
}
